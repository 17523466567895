.character {
  border: solid 1px #e5e7eb;
  width: 45px;
  height: 55px;
  font-size: 26px;
  border-radius: 10px;
}

.character--inactive {
  background-color: unset;
}
