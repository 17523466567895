::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212b36;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(145, 158, 171, 0.16);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
}
